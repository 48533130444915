export const roles = {
  Administrator: {
    paths: [
      'Support',
      'Calendar',
      'Home',
      'RecognizedPrograms',
      'Dashboard',
      'weekly_plan',
      'get_profile',
      'accomplishment_report',
      'Programs',
      'HEIs',
      'ProgramApplications',
      'HR',
      'general_services',
      'saro',
      'disbursed',
      'raod',
      'RecordsDashboard',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'planting_trees',
      'Nstp',
      'Tosf',
      'DTS',
      'dtr',
      'individualDTR',
      'hr_dashboard',
      'list_accomplishment_report',
      'individual_ar_format',
      'list_of_employee',
      'leave_credits',
      'generate_leavecreditsreport',
      'generate_leaverecordsreport',
      'leave_management',
      'employee_travel',
      'employee_travel_authority',
      'list_learning_development',
      'individual_learning_development',
      'individual_employee_info',
      'employee_service_record',
      'individual_service_record',
      'generate_pds',
      'list_contracts',
      'individual_contract_info',
      'list_positions',
      'job_position_details',
      'biometric',
      'list_of_applicants',
      'list_of_applicantshrmpsb',
      'individual_applicant_info',
      'individual_applicant_rating',
      'all_applicants_ratings',
      'list_of_awardees',
      'general_services',
      'inventory',
      'cos_payroll',
      'regular_payroll',
      'payroll_management',
      'withholding_tax',
      'contributions',
      'cos_payroll_report',
      'lateabsences',
      'adjustments',
      'general_services',
      'inventory',
      'SmsNotificationCenter',
    ],
  },

  Chief: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'HEIs',
      'Programs',
      'ProgramApplications',
      'ProgramApplications',
      'DTS',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'get_profile',
      'cos_payroll',
      'regular_payroll',
      'payroll_management',
      'withholding_tax',
      'contributions',
      'cos_payroll_report',
      'lateabsences',
      'adjustments',
      'SmsNotificationCenter',
    ],
  },
  Supervisor: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'HEIs',
      'Programs',
      'ProgramApplications',
      'DTS',
      'Tosf',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'get_profile',
      'SmsNotificationCenter',
    ],
  },

  NstpFocal: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'HEIs',
      'Programs',
      'ProgramApplications',
      'DTS',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'Nstp',
      'get_profile',
      'SmsNotificationCenter',
    ],
  },
  Records: {
    paths: [
      'Support',
      'Calendar',
      'HEIs',
      'RecognizedPrograms',
      'Programs',
      'RecordsDashboard',
      'DTS',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'get_profile',
    ],
  },
  admin: {
    paths: [
      'Support',
      'Dashboard',
      'budget',
      'chart',
      'program',
      'list_raod',
      'list_modified_raod',
      'list_disbursed',
      'reports',
      'get_profile',
    ],
  },
  accounting: {
    paths: [
      'Support',
      'Dashboard',
      'budget',
      'chart',
      'program',
      'list_raod',
      'list_modified_raod',
      'list_disbursed',
      'reports',
      'general_services',
      'inventory',
      'get_profile',
      'cos_payroll',
      'regular_payroll',
      'payroll_management',
      'withholding_tax',
      'contributions',
      'cos_payroll_report',
      'lateabsences',
      'adjustments',
    ],
  },
  COS: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'accomplishment_report',
      'weekly_plan',
      'employee_saln',
      'get_profile',
      'employee_service_record',
      'learning_development',
      'generate_pds',
      'DTS',
    ],
  },
  Regular: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'accomplishment_report',
      'weekly_plan',
      'employee_saln',
      'get_profile',
      'employee_service_record',
      'learning_development',
      'generate_pds',
      'DTS',
      'SmsNotificationCenter',
    ],
  },
  planting_trees: {
    paths: ['Dashboard', 'planting_trees', 'plant'],
  },
  planting_trees_admin: {
    paths: ['Dashboard', 'planting_trees', 'add_school'],
  },
  supply_officer: {
    paths: ['Dashboard', 'general_services', 'inventory', 'get_profile'],
  },
  HR: {
    paths: [
      'Support',
      'accomplishment_report',
      'Dashboard',
      'HR',
      'RecognizedPrograms',
      'dtr',
      'individualDTR',
      'hr_dashboard',
      'list_accomplishment_report',
      'individual_ar_format',
      'list_of_employee',
      'leave_credits',
      'generate_leavecreditsreport',
      'generate_leaverecordsreport',
      'leave_management',
      'employee_travel',
      'employee_travel_authority',
      'list_learning_development',
      'individual_learning_development',
      'individual_employee_info',
      'employee_service_record',
      'individual_service_record',
      'generate_pds',
      'generate_absencesreport',
      'list_contracts',
      'individual_contract_info',
      'list_positions',
      'job_position_details',
      'biometric',
      'list_of_applicants',
      'individual_applicant_info',
      'individual_applicant_rating',
      'all_applicants_ratings',
      'list_of_awardees',
      'DTS',
      'cos_payroll',
      'regular_payroll',
      'payroll_management',
      'withholding_tax',
      'contributions',
      'cos_payroll_report',
      'lateabsences',
      'adjustments',
      'get_profile',
    ],
  },
  RD: {
    paths: [
      'Support',
      'Calendar',
      'Dashboard',
      'RecognizedPrograms',
      'HEIs',
      'Programs',
      'ProgramApplications',
      'ProgramApplications',
      'DTS',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'RecordsDashboard',
      'accomplishment_report',
      'weekly_plan',
      'Students',
      'SpecialOrders',
      'SpecialOrdersOld',
      'CavApplications',
      'Cavs',
      'CavPayments',
      'get_profile',
      'cos_payroll',
      'regular_payroll',
      'payroll_management',
      'withholding_tax',
      'contributions',
      'cos_payroll_report',
      'lateabsences',
      'adjustments',
      'SmsNotificationCenter',
    ],
  },
  inventory_authorize: {
    paths: ['Dashboard', 'inventory'],
  },
  HRMPSB: {
    paths: [
      'Support',
      'Dashboard',
      'RecognizedPrograms',
      'list_of_applicants',
      'list_of_applicantshrmpsb',
      'list_positions',
    ],
  },

  Registrar: {
    paths: ['CAVApplication'],
  },
};
